/**
 *  var map = new YaMaps2('ymaps-map', <?php echo json_encode($dealers)?>, {
 *    map: {
 *      center: [55.75399400, 37.62209300],
 *      zoom: 5,
 *    },
 *
 *    placemark: {
 *      iconLayout: 'default#image',
 *      iconImageHref: '/i/map-mark.png',
 *      iconImageSize: [30, 70]
 *    },
 *
 *    clusterer: {
 *      gridSize : 128,
 *      clusterIcons: [{
 *        href: '/i/map-mark.png',
 *        size: [30, 70]
 *      }]
 *    },
 *
 *    citySelector: '.js-dealers-city'
 *  });
 *
 */

;(function(GLOBAL) { 'use strict';

  var defaults = {
    map: {
      type: 'yandex#map',
      center: [55.75399400, 37.62209300],
      zoom: 8,
      behaviors: ['drag', 'multiTouch', 'dblClickZoom'],
      controls: ['zoomControl']
    },

    placemark: {},

    clusterer: {
      gridSize : 64,
      groupByCoordinates: false,
      clusterDisableClickZoom: true,
      clusterOpenBalloonOnClick: true
    },

    citySelector: '.js-dealers-city'
  };


  /**
   * Конструктор карты
   *
   * @param elem {string}
   * @param data {Array}
   * @param options {Object}
   */
  GLOBAL.YaMaps2 = function(elem, data, options) {
    this.data = data;
    this.elem = elem;

    // Экстендим дефолтные настройки пользовательскими
    this.config = _deepExtend(defaults, options || {});
    this.markers = [];

    this.init();

    return this.map;
  };


  /**
   * Инициализируем карту
   */
  YaMaps2.prototype.init = function() {
    var citySelect = document.querySelector(this.config.citySelect);
    var ID = jQuery(citySelect).find(':selected').data('city');

    this.map = new ymaps.Map(this.elem, this.config.map);

    // Создаем кастомный лейаут для балуна кластера,
    this.customClusterLayout = ymaps.templateLayoutFactory.createClass([
      '{% for geoObject in properties.geoObjects %}',
      '{{ geoObject.properties.balloonContentBody|raw }}',
      '{% endfor %}'
    ].join(''));

    // Добавляем кастомный лейаут в настройки кластера
    this.config.clusterer.clusterBalloonContentLayout = this.customClusterLayout;

    this.addEventHandlers();
    this.initClusterer(this.config.clusterer);

    if (ID) return this.createCityBalloon(ID);
  };


  /**
   * Инициализируем кластер
   */
  YaMaps2.prototype.initClusterer = function() {
    this.clusterer = new ymaps.Clusterer(this.config.clusterer);
    this.map.geoObjects.add(this.clusterer);

    return this.initMarkers();
  };


  /**
   * Инициализируем маркеры
   */
  YaMaps2.prototype.initMarkers = function() {
    var _this = this;
    var data = this.data;

    for (var i in data) {
      if (data[i].coordinates.length === 2) {
        _this.markers.push(_this.createMarker(data[i]));
      }
    }

    _this.clusterer.add(this.markers);
  };


  /**
   * Создаем маркеры
   *
   * @param data {Object}
   */
  YaMaps2.prototype.createMarker = function(data) {
    var html = this.getBalloonContent(data);
    var placemark = new ymaps.Placemark(data.coordinates, {
      clusterCaption: data.dealer.name,
      balloonContentBody: html,
      city_position: data.city_position,
      city: data.city_id,
      filialId: data.id
    }, this.config.placemark);

    var self = this;
    placemark.events.add('click', function () {
      self.showBallon(html);
    });
    return placemark;
  };


  YaMaps2.prototype.showBallon = function(html) {
    $('.detail-container, .mod-item-detail').find('section').html($(html).html());
    $('.detail-container, .mod-item-detail').addClass('is-visible');
  }

  /**
   * Создаем балун
   *
   * @param data {Object}
   */
  YaMaps2.prototype.getBalloonContent = function(data) { // TODO: сделать доступным в options

    var content = '<section>';
    content += '<header class="container-header"><div class="item-symbol lifedealer"><span class="symbol"></span></div>' +
      '<div class="container-name"><h3 itemprop="name">'  + data.dealer.name + '</h3></div><div class="item-detail-close"><span class="icon-cross"></span></div>' +
      '</header>';
    content += '<div class="row">' +
      '  <div class="small-12 xlarge-6 columns">' +
      '      <ul class="small-block-grid-1">' +
      '      <li>' +
      '        <div class="item-information"><h4>Информация о магазине</h4>' +
      '        <ul>' +
      '          <li itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">' +
      '             <span class="icon-location"></span><span class="street" itemprop="streetAddress">' + data.address + '</span>' +
      '             <span class="city" itemprop="addressLocality">' + data.city + '</span>' +
      '          </li>';

    if( data.phone )
      content +=
        '<li><span class="icon-phone"></span> <span class="phone" itemprop="telephone"><a title="Call '+ data.phone + '" href="tel:' + data.phone + '">'+ data.phone +'</a></span></li>';

    if( data.link )


      content += '<li><span class="icon-globe"></span><span class="link"><a href="' + data.link + '" title="" target="_blank" itemprop="url">' + data.anchor + '</a></span></li>';

    content += '        </ul>' +
      '        </div>' +
      '      </li>' +
      '      </ul>' +
      '  </div>';

    if( data.dealer.img )
    {
      content += '<div class="small-12 xlarge-6 columns" style="padding-top: 20px">' +
        '<img src="' + data.dealer.img + '" alt="" />' +
        '</div>';
    }
      content += '      </div>';

    content += '</section>';

    return content;

    /*var content = "<div class='balloon-content'>";

    if( data.dealer.img )
    {
      content +=
        "<div class='dealer-logo fl'>"+
        "<a href='" + data.url + "'>" +
          "<img src='" + data.dealer.img + "'>" +
        "</a>"+
        "</div>";
    }
    else
    {
      content +=
        "<div class='dealer-logo fl'>"+"</div>";
    }

    content +=
      "<div class='dealer-info fl'>" +
      "<p><a href='" + data.url + "'>" + data.dealer.name + "</a>" + (data.city != "" ? " (" + data.city + ")" : "") + "</p>";

    if( data.link )
      content += "<p><span class='icn icn-url'></span> <a target='_blank' href='" + data.href + "'>" + data.link + "</a></p>";

    content += "<p><span class='icn icn-marker'></span> г." + data.city + ", " + data.address + "</p>";

    if( data.phone )
      content += "<p><span class='icn icn-phone'></span> " + data.phone +"</p>";

    if( data.worktime )
      content += "<p><span class='icn icn-time'></span> " + data.worktime + "</p>";

    content += "</div>";
    content += "</div>";

    return content;
    */
  };

  /**
   * События
   */
  YaMaps2.prototype.addEventHandlers = function() {
    var _this = this;

    $('body').on('click', this.config.citySelector, function(ev) {
      ev.preventDefault();
      var id = $(ev.target).closest('.js-dealers-city').data('filial-id');
      _this.createFilialBalloon(id);
    });

    // Слушаем клики по городам
/*    cityElems.length && (function() {
      for (var i = cityElems.length - 1; i >= 0; i--) {
        cityElems[i].addEventListener('click', function(ev) {
          ev.preventDefault();
          //var ID = ev.target.getAttribute('data-city');
          // _this.createFilialBalloon(ID);

          var id = $(ev.target).closest('.js-dealers-city').data('filial-id');
          _this.createFilialBalloon(id);
        });
      }
    })();*/
  };


  YaMaps2.prototype.createFilialBalloon = function(id) {
    for (var i in this.markers) {
      if ( Number(this.markers[i].properties.get('filialId')) === id) {
        this.showBallon(this.markers[i].properties.get('balloonContentBody'));
        this.setCenterBalloon(this.markers[i]);
        return;
      }
    }
  };


  /**
   * Создаем балун для выбранного города
   *
   * @param ID {string}
   */
  YaMaps2.createCityBalloon = function(ID) {
    var cityMarkers = [];

    for (var i in this.markers) {
      if ( ID == 'all' || this.markers[i].properties.get('city') === ID) {
        cityMarkers.push(this.markers[i]);
      }
    }

    if (cityMarkers.length) this.showCityBalloon(cityMarkers);
  };

  YaMaps2.prototype.setCenterBalloon = function(marker) {
    // var _this = this;
     var zoom = 12;
     var center = marker.geometry.getCoordinates();
     this.map.setCenter(center, zoom, { checkZoomRange: true });
  };


  /**
   * Отображаем маркеры, принадлежащие выбранному городу
   *
   * @param cityMarkers {Array}
   */
  YaMaps2.prototype.showCityBalloon = function(cityMarkers) {
    // var _this = this;
    // var zoom = 12;
    // var center = cityMarkers[0].geometry.getCoordinates();
    // var isShown = this.clusterer.getObjectState(cityMarkers[0]).isShown;

    var cityCollection = new ymaps.GeoObjectCollection();

    for (var i in cityMarkers) {
      cityCollection.add(cityMarkers[i]);
    }

    if (this.map.balloon.isOpen()) this.map.balloon.close();

    this.map.setBounds(ymaps.geoQuery(cityCollection).getBounds(), {checkZoomRange: true, zoomMargin: 10})
      .then(function() {
        if ( this.map.getZoom() > 17 ) {
          this.map.setZoom(17);
        }
      }, function (err) {}, this);

    // this.map.setCenter(center, zoom, { checkZoomRange: true });

    // // Если кластер в видимой части карты,
    // // просто показываем балун
    // if (isShown) {
    //    _showBalloon();
    // }
    //
    // // если нет, показываем балун после boundschange
    // else {
    //   this.map.events.once('boundschange', _showBalloon);
    // }
    //
    //
    // function _showBalloon() {
    //   var objectState = _this.clusterer.getObjectState(cityMarkers[0]);
    //
    //   if (objectState.isClustered) {
    //     objectState.cluster.state.set('activeObject', cityMarkers);
    //     _this.clusterer.balloon.open(objectState.cluster);
    //   }
    //
    //   else {
    //     cityMarkers[0].balloon.open();
    //   }
    // }
  };


  /**
   * _deepExtend
   *
   * @param dest {Object}
   * @param src {Object}
   */
  function _deepExtend(dest, src) {
    for (var prop in src) {
      if (typeof src[prop] === "object" && src[prop] !== null && dest[prop]) {
        _deepExtend(dest[prop], src[prop]);
      } else {
        dest[prop] = src[prop];
      }
    }

    return dest;
  };

}(window));
